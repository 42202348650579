/* Custom color theme properties */
:root {
  --f7-theme-color: #8F2C28;
  --f7-theme-color-rgb: 143, 44, 40;
  --f7-theme-color-shade: #6f221f;
  --f7-theme-color-tint: #af3631;
  /* --f7-input-placeholder-color: black; */
  --f7-block-margin-vertical: 15px;
  --f7-navbar-bg-color: #fff;
  --f7-navbar-link-color: var(--f7-color-gray);
  --f7-searchbar-input-bg-color: #f1f3f4;
}

/* Your app custom styles here */
@tailwind base;
@tailwind components;
@tailwind utilities;

.page {
  background-image: url('@/assets/background.png'); background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.active-navbar-link {
  color: var(--f7-theme-color) !important;
}

.popup .page {
  background-image: none;
}

.swiper-button-prev {
  left: -40px;
}

.swiper-button-next {
  right: -40px;
}

/* .navbar .title {
  left: 0px !important;
} */